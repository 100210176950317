var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":""}},[_c('b-row',{staticClass:"justify-content-end d-flex"},[_c('b-button',{staticClass:"mb-2 mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'nova-receita' })}}},[_vm._v(" Novo item + ")])],1),_c('b-row',{staticClass:"my-1 text-right mt-2 d-flex justify-content-start"},[_c('b-form-group',[_c('b-form-input',{attrs:{"type":"text","placeholder":"Buscar item"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('b-table',{staticClass:"p-1 w-100",attrs:{"responsive":"sm","small":"","items":_vm.filter(),"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(transaction_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.transaction_date))+" ")]}},{key:"cell(reference_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateReferenceMonthFormatter(item.reference_date))+" ")]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (_vm.numberToMonetary(item.value))))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('Actions',{attrs:{"item":item},on:{"openModal":function($event){return _vm.openEditModal(item)},"openModaDelete":function($event){return _vm.openDeleteModal(item)}}})]}}])}),_c('b-table',{staticClass:"p-1 w-100",attrs:{"responsive":"sm","small":"","items":_vm.totals,"fields":_vm.fieldsTotal},scopedSlots:_vm._u([{key:"cell(totalRealizado)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (_vm.numberToMonetary(item.totalRealizado))))+" ")]}},{key:"cell(totalPrevisto)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (_vm.numberToMonetary(item.totalPrevisto))))+" ")]}},{key:"cell(totalGeral)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (_vm.numberToMonetary(item.totalGeral))))+" ")]}}])}),_c('Modal',{attrs:{"modal":_vm.modal,"modalForm":_vm.modalForm,"editable":_vm.editable,"itemUpdate":_vm.itemUpdate},on:{"updateList":_vm.handleUpdateList,"renderList":_vm.getAll,"closeModal":function($event){return _vm.closeModal()}}})],1),(_vm.items && _vm.items.lenght > 0)?_c('b-card',{staticClass:"align-items-center"},[_c('Chart',{attrs:{"chart-options":_vm.customOptions,"chart-series":_vm.customSeries}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }