<template>
  <VueApexCharts
    :key="chartKey"
    width="500"
    type="bar"
    :options="chartOptions"
    :series="chartSeries"
  ></VueApexCharts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: { VueApexCharts },
  data() {
    return {
      chartKey: 0, // Chave inicial
    };
  },
  watch: {
    chartOptions: {
      handler() {
        this.chartKey += 1; // Incrementa a chave para forçar a renderização do componente
      },
      deep: true,
    },
    chartSeries: {
      handler() {
        this.chartKey += 1;
      },
      deep: true,
    },
  },
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
    chartSeries: {
      type: Array,
      required: true,
    },
  },
};
</script>
