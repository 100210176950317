<template>
  <div>
    <b-button
      @click="$router.push(`/lancamento-de-receita/${item.id}`)"
      v-b-tooltip.hover.lefttop.v-primary
      size="sm"
      variant="outline-secondary"
      class="mr-2"
      >Detalhes</b-button
    >
    <b-button
      @click="$router.push(`/editar-receita/${item.id}`)"
      v-b-tooltip.hover.lefttop.v-primary
      size="sm"
      variant="secondary"
      >Editar</b-button
    >
  </div>
</template>

<script>
import { VBTooltip, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
  },
  props: {
    item: { type: Object, default: () => {} },
  },
};
</script>
