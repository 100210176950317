<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="">
      <!-- CTA - adicionar receita -->
      <b-row class="justify-content-end d-flex">
        <b-button
          class="mb-2 mr-2"
          variant="primary"
          @click="$router.push({ name: 'nova-receita' })"
        >
          Novo item +
        </b-button>
      </b-row>

      <!-- Filtro -->
      <b-row class="my-1 text-right mt-2 d-flex justify-content-start">
        <b-form-group>
          <b-form-input
            type="text"
            v-model="search"
            placeholder="Buscar item"
          ></b-form-input>
        </b-form-group>
      </b-row>

      <!-- Tabela principal  -->
      <b-table
        responsive="sm"
        small
        :items="filter()"
        :fields="fields"
        class="p-1 w-100"
      >
        <template v-slot:cell(transaction_date)="{ item }">
          {{ dateFormatter(item.transaction_date) }}
        </template>
        <template v-slot:cell(reference_date)="{ item }">
          {{ dateReferenceMonthFormatter(item.reference_date) }}
        </template>
        <template v-slot:cell(value)="{ item }">
          {{ `R$ ${numberToMonetary(item.value)}` }}
        </template>
        <template v-slot:cell(actions)="{ item }">
          <Actions
            @openModal="openEditModal(item)"
            @openModaDelete="openDeleteModal(item)"
            :item="item"
          />
        </template>
      </b-table>

      <!-- Tabela dos totais -->
      <b-table
        responsive="sm"
        small
        :items="totals"
        :fields="fieldsTotal"
        class="p-1 w-100"
      >
        <template v-slot:cell(totalRealizado)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalRealizado)}` }}
        </template>
        <template v-slot:cell(totalPrevisto)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalPrevisto)}` }}
        </template>
        <template v-slot:cell(totalGeral)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalGeral)}` }}
        </template>
      </b-table>

      <!-- Modal -->
      <Modal
        :modal="modal"
        :modalForm="modalForm"
        :editable="editable"
        :itemUpdate="itemUpdate"
        @updateList="handleUpdateList"
        @renderList="getAll"
        @closeModal="closeModal()"
      />
    </b-card>
    <!-- Grafico -->
    <b-card v-if="items && items.lenght > 0" class="align-items-center">
      <Chart :chart-options="customOptions" :chart-series="customSeries" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BTable,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import Actions from './components/ActionsRevenue.vue';
import Modal from './components/ModalRevenue.vue';
import Chart from './components/Chart.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BButton,
    Modal,
    Actions,
    BFormInput,
    BFormGroup,
    Chart,
  },
  data: () => ({
    modal: false,
    modalForm: false,
    editable: false,
    itemUpdate: null,
    search: '',
    fields: [
      {
        key: 'transaction_date',
        label: 'Data',
        sortable: false,
      },
      {
        key: 'value',
        label: 'Valor',
        sortable: false,
      },
      {
        key: 'project_name',
        label: 'Projeto',
        sortable: false,
      },
      {
        key: 'reference_date',
        label: 'Referência',
        sortable: false,
      },
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
    ],

    fieldsTotal: [
      {
        key: 'totalPrevisto',
        label: 'Previsto',
        sortable: false,
      },
      {
        key: 'totalRealizado',
        label: 'Realizado',
        sortable: false,
      },
      {
        key: 'totalGeral',
        label: 'Total',
        sortable: false,
      },
    ],
    items: [],
    totals: [],
    costsCategoryList: false,
    customOptions: {
      chart: {
        height: 350,
        type: 'bar',
      },
      xaxis: {
        categories: [],
      },
    },
    customSeries: [
      {
        name: 'Receitas Realizadas',
        data: [],
      },
    ],
  }),

  created() {
    this.getAll();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    // Entidades
    async processChartData(revenues) {
      const monthlyData = {};
      const chartCategories = [];

      revenues.forEach((revenue) => {
        const month = this.dateReferenceMonthFormatter(revenue.reference_date);
        if (!monthlyData[month]) {
          monthlyData[month] = 0;
          chartCategories.push(month);
        }
        monthlyData[month] += revenue.value;
      });

      this.customOptions.xaxis.categories = chartCategories;
      this.customSeries[0].data = Object.values(monthlyData);
      this.customOptions.dataLabels = {
        formatter: (val) => `R$ ${this.numberToMonetary(val)}`,
      };
    },

    async getAll() {
      this.items = [];
      this.$store
        .dispatch('getAllRevenues', {
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.items = resp.revenues;
            this.totals = arrayTranform;
            this.processChartData(resp.revenues);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Controladores
    openEditModal(item) {
      this.modal = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modal = true;
      this.modalForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modal = true;
      this.modalForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modal = false;
      this.editable = false;
    },

    filter() {
      if (this.search.trim() === '') {
        // If the search input is empty, return all items
        return this.items;
      } else {
        // If there's a search term, filter the items
        return this.items.filter((item) => {
          return (
            item.project_name.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },

    handleUpdateList() {
      this.getAll();
    },
  },
};
</script>
